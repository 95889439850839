<!-- 知识库 -->
<template>
  <div class="bg container">
    <div class="conBox">
      <el-header>
        <div class="dfc p15">
          <el-page-header @back="$router.go(-1)" :content="$route.params.detail.kb_name"> </el-page-header>
        </div>
      </el-header>
      <div style="background: rgba(28, 28, 36, 1);border-radius: 10px">
        <div class="chatBg" ref="scrollDom" id="scrollDom">
          <!-- 聊天框 -->
          <div class="mb20" v-for="(i, idx) in chatList" :key="idx">
            <div class="df mb20" style="justify-content: end">
              <div class="mr15 chatBox problem">{{ i.user }}</div>
              <img src="@/assets/img/chat/user.png" />
            </div>
            <div class="df" v-show="i.sys">
              <img src="@/assets/img/chat/GPT.png" />
              <div class="ml15 chatBox reply">
                <div>{{ i.sys }}</div>
                <div class="mt8 source" v-for="(s, idx) in i.source" :key="idx">
                  <div>数据来源{{ idx }}: {{ s.file_name }}</div>
                  <div>相关性：{{ s.score }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 输入框 -->
        <div class="mt10 mb10 ipt dfc">
          <el-input class="mr15 chat-input" ref="ipt" placeholder="请输入问题" autofocus :disabled="disabled" v-model="problem" @keyup.enter.native="send" clearable> </el-input>
          <el-button class="clear-btn" type="danger"  :disabled="disabled" @click="clear">清 空</el-button>
          <el-button class="send-btn" type="primary" :disabled="disabled" @click="send">发 送</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { fetchEventSource } from "@microsoft/fetch-event-source";

export default {
  name: "knowledgeChat",
  data() {
    return {
      current: 0,
      disabled: false,
      menu: [
        {
          title: "主页",
          pageName: "home",
          params: {},
        },
        {
          title: "话术方案",
          pageName: "explain",
          params: {},
        },
      ],
      chatList: [],
      problem: "",
      pageInfo: { total: 1, page: 1, item: 999 },
    };
  },
  mounted() {
    // console.log(fetchEventSource);
  },
  methods: {
    scroll() {
      console.log(this.$refs.scrollDom.scrollHeight);
      // this.$refs.scrollDom.scrollTo({ behavior: "smooth" });
      document.getElementById("scrollDom").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    },
    async send() {
      this.chatList.push({
        user: this.problem,
        sys: "",
        source: [],
      });
      this.disabled = true;
      this.$nextTick(() => {
        // 滚动到ref为"scrollDom"的元素
        document.getElementById("scrollDom").scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      });

      const loading = this.$loading({
        lock: true,
        text: "正在查询中，请稍候",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let par = {
        user_id: localStorage.getItem("token"),
        localhosts: this.$onlyId,
        kb_ids: this.$route.params.detail.kb_id,
        question: this.problem,
        streaming: true,
      };
      this.problem = "";
      let fetUrl = localStorage.getItem("fetUrl");
      const ctrl = new AbortController(); // 创建AbortController实例，以便中止请求
      await fetchEventSource(
         fetUrl + "api/local_doc_qa/local_doc_chats",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: ["text/event-stream", "application/json"],
          },
          body: JSON.stringify(par),
          openWhenHidden: true, // 取消visibilityChange事件
          signal: ctrl.signal, // AbortSignal
          onopen: () => {},
          onmessage: (ev) => {
            this.$nextTick(() => {
              // 滚动到ref为"scrollDom"的元素
              document.getElementById("scrollDom").scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
              });
            });
            loading.close();
            const data = JSON.parse(ev.data);
            console.log("source_documents", data.source_documents);
            let len = this.chatList.length - 1;
            let sys = this.chatList[len].sys;
            sys += data.response;
            this.$set(this.chatList[len], "sys", sys);
            if (data.source_documents.length > 0)
              this.$set(this.chatList[len], "source", data.source_documents);
          },
          onclose: (c) => {
            this.disabled = false;
            console.log("ipt", this.$refs.ipt);
            if (!this.chatList[0].sys) {
              this.$message.error(
                "当前知识库为空，请上传文件或等待文件解析完毕"
              );
              loading.close();
            }

            this.$nextTick((_) => {
              this.$refs.ipt.focus();
            });
          },
          onerror: (err) => {
            this.$message.error("当前知识库为空，请上传文件或等待文件解析完毕");
            loading.close();
            console.log("err", err);
            ctrl.abort();
            throw err;
          },
        }
      );
    },

    async clear() {
      this.chatList = [];
    },
  },
};
</script>
<style lang="less" scoped>
.clear-btn{
  background: rgba(166, 166, 166, 1);
  border: none;
  margin-left: 30px;
}
.submit_btn{
  border: none;
  background: rgba(42, 130, 228, 1);
  margin-left: 30px;
}
.chat-input{
  border: none;
    border-radius: 10px;
  background: rgba(28, 28, 36, 1);
}

::v-deep{
  .el-input--suffix .el-input__inner{
    background: rgba(28, 28, 36, 1);
    border: none;
    border-radius: 10px;
    color: white;
  }
  .el-page-header{
    float: left;
    margin: 10px;
  }
  .el-icon-back{
    display: none;
  }
  .el-page-header__content{
    width: auto;
    height: 27px;
    opacity: 1;
    /** 文本1 */
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 26.06px;
    color: rgba(11, 224, 178, 1);
    text-align: left;
    vertical-align: top;
  }
  .el-page-header__left{
  width: 68px;
  height: 34px;
  opacity: 1;
  border-radius: 30px;
  background: rgba(25, 103, 191, 1);
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 6px 12px 7px 19px;
   color: white;
   margin-top: -6px;
  }
}
.conBox {
  position: relative;
  margin: 20px;
  background: rgba(44, 50, 64, 1);
  padding: 10px 15px;
  min-height: 89vh;
  border-radius: 8px;

  .cHead {
    font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
    font-weight: 700;
    font-size: 16px;
    color: #202020;
    line-height: 19px;
    text-align: left;
  }
}

.menu-item {
  .ant-menu-item {
    background-color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0 0px !important;
  }
}

.ipt {
  position: relative;
  top: 20px;
  bottom: 20px;
  left: 1.5rem;
  right: 70px;
  width: 98%;
  // height: 83px;
  padding-right: 41px;
  float: left;
}
.el-color-picker__icon, .el-input, .el-textarea{
  width: 85%;
}
img {
  width: 40px;
  height: 40px;
}
.chatBg {
  position: relative;
  height: 680px;
  overflow-y: scroll;
  padding: 20px 20px 0;
}
.chatBox {
  background: #fff;
  border-radius: 8px;
  max-width: 62vw;
  text-align: left;
  padding: 12px 18px;
  box-shadow: 0px 0px 13px #ece9e9;

  .source {
    color: #909399;
    font-size: 14px;
  }
}
::v-deep {
  .el-aside {
    border: 0;
  }
  .el-textarea__inner {
    background: #fff !important;
  }
  .el-header {
    padding: 0;
  }
}
</style>
